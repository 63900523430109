import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { FaSpinner } from "react-icons/fa";

import { withLayout } from "../parts/Layout";

// markup
const IndexPage = () => {
  useEffect(() => navigate('/app'), []);

  return (
    <div className="flex-1 flex flex-col min-h-screen bg-depalmaBlue text-depalmaWhite justify-between">
      <div className="flex-1"></div>
      <main className="mb-auto flex-0 text-center items-center">
        <FaSpinner className="text-6xl animate-spin mx-auto" />
      </main>
      <div className="flex-1"></div>
    </div>
  )
}

export default withLayout(IndexPage);
